import {
  useState,
  useEffect,
  useCallback,
  useRef,
  React,
  useContext,
} from "react"
import { Link } from "gatsby"
import logo from "../../../media/images/APS_Logo_White_248x284.png"
import audio from "../../../media/audio/galaxy-audio.mp3"
import { s } from "../../../style/index"
import phone from "../../../media/images/icons/phone.png"
import email from "../../../media/images/icons/email.png"
import { Context, DispatchContext } from "../../../store/GlobalStateProvider"
import { AudioButton, Hamburger, NeonModeButton } from "../../buttons"

const devTools = 0
const Header = ({
  siteTitle,
  path,
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  navElements,
  optionsActive,
  setOptionsActive,
}) => {
  const [y, setY] = useState(typeof window == !undefined ? window.scrollY : 0)
  const [menu, setMenu] = useState("big")
  const state = useContext(Context)
  const neonsColor = state.neonMode.color
  const neonOn = state.neonMode.isActive
  const style = DynamicCSS(menu)
  const music = useRef()
  const handleNavigation = useCallback(
    e => {
      const window = e.currentTarget
      if (y > window.scrollY) {
        setMenu("big")
      } else if (y < window.scrollY) {
        setMenu("small")
      }
      setY(window.scrollY)
    },
    [y]
  )

  useEffect(() => {
    setY(window.scrollY)
    window.addEventListener("scroll", handleNavigation)

    return () => {
      window.removeEventListener("scroll", handleNavigation)
    }
  }, [handleNavigation])

  const Button = ({ title, url, color }) => {
    return (
      <Link
        to={url}
        css={{
          backdropFilter: "blur(5px)",
          fontWeight: 700,
          textAlign: "center",
          textDecoration: "none",
          outline: 0,
          fontSize: "1.1rem",
          padding: "12px 35px",
          display: "inline-block",
          transform: "skew(-21deg)",
          border: "2px solid white",
          ":hover": {
            transform: "skew(-21deg) scale(1.1)",
            zIndex: 1,
            border: `2px solid ${color}`,
            boxShadow: `0px 0px 5px ${color}, 0px 0px 20px ${color}, 0px 0px 50px ${color}`,
          },
          span: {
            transform: "skew(21deg)",
            textDecoration: "none",
            color: "white",
            display: "inline-block",
          },
        }}
      >
        <span>{title}</span>
      </Link>
    )
  }
  const dispatch = useContext(DispatchContext)
  return (
    <div css={[style.menuBar, path === "/dev/" && { display: "none" }]}>
      {/* eslint-disable jsx-a11y/media-has-caption*/}
      <audio css={{ display: "none" }} ref={music} controls loop>
        <source src={audio} type="audio/mpeg" />
      </audio>
      <div css={style.backgroundOnMenu} />
      <Link to="/" css={style.logoBox}>
        <img css={{ with: "100%", height: "100%" }} src={logo} alt="" />
      </Link>
      <div css={style.navBar}>
        <div
          css={{
            border: devTools === 1 ? "1px solid red" : "",
            height: "30%",
            display: menu === "big" ? "flex" : "none",
            flexDirection: "row",
            gap: "10px",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div css={{ display: "flex", flexDirection: "row", gap: "5px" }}>
            <div css={{ height: "18px" }}>
              <img css={{ width: "100%", height: "100%" }} src={email} alt="" />
            </div>
            <p css={{ color: "white", margin: 0 }}>k.koch@zohomail.eu</p>
          </div>
          <div css={{ display: "flex", flexDirection: "row", gap: "5px" }}>
            <div css={{ width: "20px", height: "20px" }}>
              <img css={{ width: "100%", height: "100%" }} src={phone} alt="" />
            </div>
            <p css={{ color: "white", margin: 0 }}>+353 83 116 6159</p>
          </div>
          <AudioButton music={music} size={"30px"} />
          <NeonModeButton
            size={25}
            dispatch={dispatch}
            neonsActualColor={neonsColor}
            neonsOn={neonOn}
            optionsActive={optionsActive}
            setOptionsActive={setOptionsActive}
          />
        </div>
        <div
          css={{
            border: devTools === 1 ? "1px solid green" : "",
            height: menu === "big" ? "70%" : "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginRight: "10px",
          }}
        >
          {navElements
            .sort((a, b) => {
              return a.position - b.position
            })
            .map(el => {
              const { title, url, position } = el
              return (
                <Button
                  title={title}
                  url={url}
                  color={
                    neonOn ? (neonsColor ? neonsColor : "#A9A9A9") : "#A9A9A9"
                  }
                  position={position}
                />
              )
            })}
        </div>
      </div>
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          zIndex: 11,
          [s.desktop]: {
            display: "none",
          },
        }}
      >
        <NeonModeButton
          dispatch={dispatch}
          neonsActualColor={neonsColor}
          neonsOn={neonOn}
          optionsActive={optionsActive}
          setOptionsActive={setOptionsActive}
        />
        <AudioButton music={music} size={"50px"} />
        <Hamburger open={isMobileMenuOpen} setOpen={setIsMobileMenuOpen} />
      </div>
    </div>
  )
}
const DynamicCSS = menu => {
  return {
    menuBar: {
      width: "100%",
      display: "flex",
      flexDirection: "Row",
      justifyContent: "space-between",
      zIndex: 10,
      top: 0,
      padding: "10px 0px",
      position: "fixed",
      [s.tablet]: {
        alignItems: "center",
      },
    },
    backgroundOnMenu: {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 10,
      backgroundColor: "rgba(0,0,0,0.3)",
      backdropFilter: "blur(6px)",
    },
    logoBox: {
      border: devTools === 1 ? "1px solid blue" : "",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 12,
      transition: "height 0.6s ease",
      marginLeft: "50px",
      height: menu === "big" ? "120px" : "80px",
      [s.tablet]: { height: "80px !important", marginLeft: "14px" },
    },

    navBar: {
      border: devTools === 1 ? "1px solid blue" : "",
      position: "relative",
      width: "900px",
      zIndex: 11,
      margin: "0 5px 0 5px",
      [s.tablet]: {
        display: "none",
      },
    },
  }
}

export default Header
